import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import validator from 'validator'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
//import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {DongBo} from '../core/_models'
import {createCauHinhDongBo, updateCauHinhDongBo} from '../core/_requests'
import {getMaDinhDanhs} from '../../../madinhdanh/madinhdanh/core/_requests'
import {maDinhDanh} from '../../../madinhdanh/madinhdanh/core/_models'
import {Select} from 'antd'
import compareLabelMatch from '../../../../../untils/compare-label-match'

type Props = {
  isLoading: boolean
  data: DongBo
}

const editManufactureSchema = Yup.object().shape({
  Name: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Tên không được để trống'),
  Info: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Thông tin không được để trống'),
  Url: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Địa chỉ API không được để trống'),
  ApiKey: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Key không được để trống'),
  NguonId: Yup.string().required('Mã định danh không được để trống'),
  ObjectSend: Yup.string().required('Đối tượng không được để trống'),
})

const EditModalForm: FC<Props> = ({data, isLoading}) => {
  const {auth} = useAuth()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [listMaDinhDanh, setListMaDinhDanh] = useState<Array<maDinhDanh>>([])
  const [nguonAddress, setNguonAddress] = useState<string>('')

  const [manufactureForEdit] = useState<DongBo>({
    ...data,
    Name: data.Name || '',
    Info: data.Info || '',
    Type: data.Type || 0,
    Url: data.Url || '',
    ApiKey: data.ApiKey || '',
    NguonAddress: data.NguonAddress || '',
    NguonId: data.NguonId || '',
    ObjectSend: data.ObjectSend || 0,
  })

  useEffect(() => {
    if (data) {
      setNguonAddress(data.NguonAddress ?? '')
    }
  }, [data])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate('')
  }

  const formik = useFormik({
    initialValues: manufactureForEdit,
    validationSchema: editManufactureSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      values.NguonAddress = nguonAddress
      try {
        if (checkURL(values.Url!))
          if (values.Id !== '0') {
            await updateCauHinhDongBo(auth?.configs, values)
              .then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Cập nhật thông tin thành công',
                  text: ' ',
                  showConfirmButton: false,
                  timer: 1500,
                })
                setSubmitting(true)
                cancel(true)
              })
              .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: error.response.data || 'Cập nhật thông tin thất bại',
                  text: ' ',
                  confirmButtonText: 'Đóng lại',
                })
              })
          } else {
            await createCauHinhDongBo(auth?.configs, values)
              .then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Thêm mới thành công',
                  text: ' ',
                  showConfirmButton: false,
                  timer: 1500,
                })
                setSubmitting(true)
                cancel(true)
              })
              .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: error.response.data || 'Thêm mới thất bại',
                  text: ' ',
                  confirmButtonText: 'Đóng lại',
                })
              })
          }
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  const checkURL = (url: string) => {
    if (!validator.isURL(url)) {
      Swal.fire({
        icon: 'error',
        title: 'Địa chỉ API không đúng định dạng',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      })
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    getMaDinhDanhs(auth?.configs).then((data) => {
      if (data.Data) setListMaDinhDanh(data.Data)
    })
  }, [])

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row' style={{alignItems: 'center'}}>
            <div className='col-3'>
              <label className='required fw-bold fs-6 mb-2'>Tên</label>
            </div>
            <div className='col-9'>
              <input
                placeholder='Tên'
                {...formik.getFieldProps('Name')}
                type='text'
                name='Name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Name && formik.errors.Name},
                  {
                    'is-valid': formik.touched.Name && !formik.errors.Name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Name && formik.errors.Name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Name}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Input group */}

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Thông tin</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <input
                placeholder='Thông tin'
                type='text'
                {...formik.getFieldProps('Info')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                name='Info'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {/* end::Input */}
              {formik.touched.Info && formik.errors.Info && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.Info}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Loại dữ liệu</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <select
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('Type')}
                className={clsx(
                  'form-select form-select-solid fw-bolder',
                  {'is-invalid': formik.touched.Type && formik.errors.Type},
                  {
                    'is-valid': formik.touched.Type && !formik.errors.Type,
                  }
                )}
              >
                <option value={0}>MBF.TTN</option>
                <option value={1}>VTC.TTN</option>
              </select>
              {formik.touched.Type && formik.errors.Type && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.Type}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Đối tượng</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <select
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('ObjectSend')}
                className={clsx(
                  'form-select form-select-solid fw-bolder',
                  {'is-invalid': formik.touched.ObjectSend && formik.errors.ObjectSend},
                  {
                    'is-valid': formik.touched.ObjectSend && !formik.errors.ObjectSend,
                  }
                )}
              >
                <option value={0}>Hệ thống thông tin nguồn Tỉnh</option>
                <option value={1}>Nhà cung cấp địa phương</option>
              </select>
              {formik.touched.ObjectSend && formik.errors.ObjectSend && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.ObjectSend}
                  </span>
                </div>
              )}
            </div>
          </div>

          <hr className='mb-0' />
          <h3 className='m-0' style={{color: '#A1A9C7'}}>
            Cấu hình
          </h3>
          <div className='row' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Địa chỉ API</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <input
                placeholder='Địa chỉ API'
                {...formik.getFieldProps('Url')}
                type='text'
                name='Url'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Url && formik.errors.Url},
                  {
                    'is-valid': formik.touched.Url && !formik.errors.Url,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Url && formik.errors.Url && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Url}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Key</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <input
                placeholder='Key'
                {...formik.getFieldProps('ApiKey')}
                type='text'
                name='ApiKey'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.ApiKey && formik.errors.ApiKey},
                  {
                    'is-valid': formik.touched.ApiKey && !formik.errors.ApiKey,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.ApiKey && formik.errors.ApiKey && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.ApiKey}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Mã định danh</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <Select
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                showSearch={true}
                filterOption={compareLabelMatch}
                placeholder='Chọn mã định danh'
                defaultValue={data.NguonId ?? ''}
                onChange={(value) => {
                  const nguonAddress = listMaDinhDanh.find((item) => item.NguonID === value)?.Name
                  setNguonAddress(nguonAddress ?? '')
                  formik.setFieldValue('NguonId', value)
                }}
                allowClear
                options={listMaDinhDanh.map((item) => ({
                  label: `${item.NguonID} - ${item.Name}`,
                  value: item.NguonID,
                }))}
                className={clsx(
                  'form-select form-select-solid fw-bolder',
                  {'is-invalid': formik.touched.NguonId && formik.errors.NguonId},
                  {
                    'is-valid': formik.touched.NguonId && !formik.errors.NguonId,
                  }
                )}
              />

              {formik.touched.NguonId && formik.errors.NguonId && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.NguonId}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Địa chỉ nguồn</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <input
                placeholder='Địa chỉ nguồn'
                type='text'
                name='NguonAddress'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                disabled
                value={nguonAddress}
              />
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className=' fw-bold fs-6 mb-2'>Tên đăng nhập</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <input
                placeholder='Tên đăng nhập'
                {...formik.getFieldProps('UserName')}
                type='text'
                name='UserName'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.UserName && formik.errors.UserName},
                  {
                    'is-valid': formik.touched.UserName && !formik.errors.UserName,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.UserName && formik.errors.UserName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.UserName}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-3'>
              {/* begin::Label */}
              <label className=' fw-bold fs-6 mb-2'>Mật khẩu</label>
              {/* end::Label */}
            </div>
            <div className='col-9'>
              <input
                placeholder='Mật khẩu'
                {...formik.getFieldProps('Password')}
                type='password'
                name='Password'
                autoComplete='new-password'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Password && formik.errors.Password},
                  {
                    'is-valid': formik.touched.Password && !formik.errors.Password,
                  }
                )}
                disabled={formik.isSubmitting || isLoading}
              />
              {formik.touched.Password && formik.errors.Password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.Password}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3 btn-cancel fw-b'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Hủy bỏ
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label fw-b'>
              {data.Id === '0' ? 'Thêm mới' : 'Cập nhật'}
            </span>

            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <Loading />}
    </>
  )
}

export {EditModalForm}
