import axios, {AxiosResponse} from 'axios'
import {DynamicConfig} from '../../../../configuration/config'
import {ID, QueryState, Response} from '../../../../_metronic/helpers'
import {Districts, Wards} from '../../diachi/diachi-list/core/_models'
import {UsersQueryResponse} from '../../nguoidung/nguoidung-list/core/_models'
import {
  City,
  DeviceInfo,
  DeviceInfoQueryResponse,
  District,
  FileResponse,
  LocationArr,
  Ward,
} from './_models'

const DEVICE_INFO = '/DeviceInfo'
const UPLOAD_FILE = '/uploadFile'
const USER_GROUPS = '/userGroups'
const DEVICE_GROUPS = '/deviceGroups'
const MANUFACTURE = '/Manufacture'
const DEVICESTATEREPORT = '/deviceStateReport'
const LOCATION = '/location'

const getDeviceInfoByFilter = (
  configs: DynamicConfig,
  state: QueryState
): Promise<DeviceInfoQueryResponse> | undefined => {
  if (state.filter === undefined) return undefined
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/get-DeviceInfoByFilter2`, state.filter, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getDeviceInfoByDeviceType = (configs: DynamicConfig): Promise<DeviceInfoQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${DEVICE_INFO}/get-deviceInfoByDeviceType`,
      {DeviceType: 0},
      {
        headers: {
          currentPage: 1,
          pageSize: 1000,
        },
      }
    )
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getDeviceInfoByDistric = (configs: DynamicConfig, obj: any) => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/get-DeviceInfoByDistric`, obj, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const getDeviceInfoByAddress = (configs: DynamicConfig, obj: any) => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/get-DeviceInfoByAddress`, obj, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<DeviceInfoQueryResponse>) => d.data)
}

const createDeviceInfo = (
  configs: DynamicConfig,
  deviceInfo: DeviceInfo
): Promise<DeviceInfo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/create-DeviceInfo`, {
      Imei: deviceInfo.Imei,
      GroupID: deviceInfo.GroupID,
      Address: deviceInfo.Address,
      Info: deviceInfo.Info,
      DeviceType: deviceInfo.DeviceType,
      Longitude: deviceInfo.Longitude,
      Latitude: deviceInfo.Latitude,
      ManufactureId: deviceInfo.ManufactureId,
      InitTime: deviceInfo.InitTime,
      WarrantyTime: deviceInfo.WarrantyTime,
      ServiceType: deviceInfo.ServiceType,
      CustomerPhone: deviceInfo.CustomerPhone,
    })
    .then((response: AxiosResponse<Response<DeviceInfo>>) => response.data)
    .then((response: Response<DeviceInfo>) => response.data)
}

const getDeviceById = (configs: DynamicConfig, id: string): Promise<DeviceInfo> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/get-deviceById`, {Id: id})
    .then((response: AxiosResponse<DeviceInfo>) => response.data)
}

const uploadFile = (configs: DynamicConfig, file: Blob): Promise<FileResponse> => {
  const formData = new FormData()
  let tmps = file.type.split('/')
  formData.append('FileExtension', tmps[tmps.length - 1])
  formData.append('UploadedFile', file)
  return axios
    .post(`${configs.apiUrl}${UPLOAD_FILE}/uploadFile`, formData)
    .then((response: AxiosResponse<FileResponse>) => response.data)
}

const updateDeviceDisplayInfo = (
  configs: DynamicConfig,
  deviceInfo: DeviceInfo
): Promise<DeviceInfo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/update-DeviceDisplayInfo`, {
      Id: deviceInfo.Id,
      Imei: deviceInfo.Imei,
      Title: deviceInfo.Title,
      Content: deviceInfo.Content,
      Url: deviceInfo.Url,
      ContentField: deviceInfo.ContentField,
    })
    .then((response: AxiosResponse<Response<DeviceInfo>>) => response.data)
    .then((response: Response<DeviceInfo>) => response.data)
}

const updateDeviceInfo = (
  configs: DynamicConfig,
  deviceInfo: DeviceInfo
): Promise<DeviceInfo | undefined> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/update-DeviceInfo`, {
      Id: deviceInfo.Id,
      Imei: deviceInfo.Imei,
      GroupID: deviceInfo.GroupID,
      Address: deviceInfo.Address,
      Info: deviceInfo.Info,
      DeviceType: deviceInfo.DeviceType,
      Longitude: deviceInfo.Longitude,
      Latitude: deviceInfo.Latitude,
      ManufactureId: deviceInfo.ManufactureId,
      InitTime: deviceInfo.InitTime,
      WarrantyTime: deviceInfo.WarrantyTime,
      ServiceType: deviceInfo.ServiceType,
      CustomerPhone: deviceInfo.CustomerPhone,
    })
    .then((response: AxiosResponse<Response<DeviceInfo>>) => response.data)
    .then((response: Response<DeviceInfo>) => response.data)
}

const deleteDeviceInfo = (configs: DynamicConfig, id: string): Promise<void> => {
  return axios.put(`${configs.apiUrl}${DEVICE_INFO}/delete-deviceInfo`, {Id: id}).then(() => {})
}

const deleteSelectedUsers = (configs: DynamicConfig, userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${configs.apiUrl}${DEVICE_INFO}/${id}`))
  return axios.all(requests).then(() => {})
}

const getListUserGroup = (configs: DynamicConfig): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${USER_GROUPS}/get-userGroups`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListUserGroupByGroupId = (
  configs: DynamicConfig,
  userGroupIds: string[]
): Promise<UsersQueryResponse> => {
  return axios
    .post(`${configs.apiUrl}${USER_GROUPS}/get-userGroupByGroupId`, userGroupIds, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListDeviceGroups = (configs: DynamicConfig): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${DEVICE_GROUPS}/get-deviceGroups`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListDeviceGroupByUsername = (
  configs: DynamicConfig,
  username: string
): Promise<UsersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${DEVICE_GROUPS}/get-deviceGroupByUser`,
      {Username: username},
      {
        headers: {
          currentPage: 1,
          pageSize: 1000,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListManufacture = (configs: DynamicConfig): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${MANUFACTURE}/get-manufactures`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getLocationByCityId = (configs: DynamicConfig): Promise<City> => {
  return axios
    .post(`${configs.apiUrl}${LOCATION}/get-locationByCityId`, {CityId: configs.cityId})
    .then((response: AxiosResponse<City>) => response.data)
}

const getDistrictByCityIdFilter = (
  configs: DynamicConfig,
  cityId: string
): Promise<Array<Districts>> => {
  return axios
    .post(`${configs.apiUrl}${LOCATION}/get-districtByCityId`, {CityId: cityId})
    .then((response: AxiosResponse<Array<Districts>>) => response.data)
}

const getWardByDistrictIdFilter = (
  configs: DynamicConfig,
  cityId: string,
  districtId: string
): Promise<Array<Wards>> => {
  return axios
    .post(`${configs.apiUrl}${LOCATION}/get-wardByDistrictId`, {
      CityId: cityId,
      DistrictId: districtId,
    })
    .then((response: AxiosResponse<Array<Wards>>) => response.data)
}

const checkDeleteDevice = (configs: DynamicConfig, Id: string, onError: any): Promise<void> => {
  return axios
    .put(`${configs.apiUrl}${DEVICE_INFO}/check-delete-deviceInfo`, {Id: Id})
    .then(() => {})
    .catch((e) => onError(Id, e.response.data))
}

const controlStopDeviceInfo = (
  configs: DynamicConfig,
  imei: string,
  control: boolean,
  onSuccess: any,
  onError: any
): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/control-stopDeviceInfo`, {
      Imei: imei,
      Control: control,
    })
    .then(() => {})
    .then((res) => onSuccess(res))
    .catch((e) => onError(e.response.data))
}

const controlVolumeDeviceInfo = (
  configs: DynamicConfig,
  imei: string,
  volume: number,
  onSuccess: any,
  onError: any
): Promise<void> => {
  return axios
    .post(`${configs.apiUrl}${DEVICE_INFO}/control-volumeDeviceInfo`, {
      Imei: imei,
      Volume: volume,
    })
    .then(() => {})
    .then((res) => onSuccess(res))
    .catch((e) => onError(e.response.data))
}

const getProvinces = (configs: DynamicConfig): Promise<Array<City>> => {
  return axios
    .get(`${configs.apiUrl}${LOCATION}/get-listCityByUser`)
    .then((response: AxiosResponse<Array<City>>) => response.data)
}

const getListDistrictByCityId = (configs: DynamicConfig, id: string): Promise<Array<District>> => {
  return axios
    .post(`${configs.apiUrl}${DEVICESTATEREPORT}/get-listDistrictByCityId`, {
      CityId: id,
    })
    .then((response: AxiosResponse<Array<District>>) => response.data)
}

const getListWardByDistrictId = (
  configs: DynamicConfig,
  cityId: string,
  districtId: string
): Promise<Array<Ward>> => {
  return axios
    .post(`${configs.apiUrl}${DEVICESTATEREPORT}/get-ListWardByDistrictId`, {
      CityId: cityId,
      DistrictId: districtId,
    })
    .then((response: AxiosResponse<Array<Ward>>) => response.data)
}

export {
  getDeviceInfoByDeviceType,
  getDistrictByCityIdFilter,
  getWardByDistrictIdFilter,
  getDeviceInfoByDistric,
  getDeviceInfoByAddress,
  deleteDeviceInfo,
  deleteSelectedUsers,
  createDeviceInfo,
  getDeviceById,
  uploadFile,
  updateDeviceDisplayInfo,
  updateDeviceInfo,
  getListUserGroup,
  getListUserGroupByGroupId,
  getListDeviceGroups,
  getListDeviceGroupByUsername,
  getLocationByCityId,
  getListWardByDistrictId,
  getDeviceInfoByFilter,
  checkDeleteDevice,
  getListManufacture,
  controlStopDeviceInfo,
  controlVolumeDeviceInfo,
  getProvinces,
  getListDistrictByCityId,
}
