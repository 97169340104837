import {Dispatch, SetStateAction} from 'react'
import {
  CoverageDistrict,
  ReportDeviceInfoTotal,
  ReportScheduleDashboards,
} from '../../../app/pages/dashboard/service/dashboard.model'

export type ID = undefined | null | number

export type PaginationState = {
  currentPage: number
  pageSize: number
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
  totalPages: number
  totalItems: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
  search2?: string
  tmp?: string
}

export type SearchDateState = {
  DeviceGroupName?: string
  from?: string
  to?: string
}

export type SearchContent = {
  title?: string
  createUser?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
  Data?: T
  TotalItems?: number
  CurrentPage?: number
  PageSize?: number
  TotalPages?: number
  Pagination?: PaginationState
}

export type ResponseX<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
  Data: {
    Data?: T
    TotalItems?: number
    CurrentPage?: number
    PageSize?: number
    TotalPages?: number
    Pagination?: PaginationState
  }

  Total?: Total
}

export type Total = {
  Coverage?: number
  DeviceTotal?: number
  DiscoonnectDeviceTotal?: number
  RunningDeviceTotal?: number
}

export type ResponseDashboard<T1> = {
  listDistrictDashboard?: never[]
  reportDeviceInfoTotal?: ReportDeviceInfoTotal
  reportScheduleDashboards?: Array<ReportScheduleDashboards>
}

export type ResponseDashboard2 = {
  listDistrictDashboard?: Array<CoverageDistrict>
  reportDeviceInfoTotal?: ReportDeviceInfoTotal
}

export type ResponseReportFeedbackDashboard<T> = {
  pagingfeedbackDto?: {
    Data?: T
    TotalItems?: number
    CurrentPage?: number
    PageSize?: number
    TotalPages?: number
  }
  ActionFinishTotal?: number
  ActionWaitingTotal?: number
  ActionProcessingTotal?: number
}

export type ResponseReportFeedbackStateDashboard<T> = {
  Data?: T
  TotalItems?: number
  CurrentPage?: number
  PageSize?: number
  TotalPages?: number
  // ActionFinishTotal?: number
  // ActionWaitingTotal?: number
  // ActionProcessingTotal?: number
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & SearchDateState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  totalItems: 0,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseContextPropsModel<T> = {
  response?: T | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseContextPropsX<T> = {
  response?: ResponseX<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseContextPropsForMap<T> = {
  response?: Array<T>
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseContextPropsDashboard<T1> = {
  response?: ResponseDashboard2
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: string
  setItemIdForUpdate: Dispatch<SetStateAction<string>>
  isAllSelected: boolean
  disabled: boolean

  selectedObject: any
  setSelectedObject: Dispatch<SetStateAction<any>>

  ItemIdForAdd?: any
  setItemIdForAdd: Dispatch<SetStateAction<any>>

  ItemIdForAddDistrict?: any
  setItemIdForAddDistrict: Dispatch<SetStateAction<any>>

  ItemIdForAddWard?: any
  setItemIdForAddWard: Dispatch<SetStateAction<any>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},

  isAllSelected: false,
  disabled: false,

  selectedObject: {},
  setSelectedObject: () => {},

  setItemIdForAdd: () => {},

  setItemIdForAddDistrict: () => {},

  setItemIdForAddWard: () => {},
}
