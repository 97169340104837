import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import '../_metronic/assets/css/css_custome.css'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {getConfigs} from './modules/auth/core/_requests'
import UsbConnect from './modules/auth/components/UsbConnect'

const App = () => {
  const {auth, saveAuth} = useAuth()
  useEffect(() => {
    getConfigs().then((data) => {
      if (auth) {
        auth.configs = data.data
        saveAuth(auth)
      }
      let link: any = document.querySelector("link[rel~='icon']")
      link.href = data.data.shortcutIcon
    })
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {localStorage.getItem('isUsbKey') && (
        <div>
          <UsbConnect />
        </div>
      )}
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
