import {SearchOutlined} from '@ant-design/icons'
import {Button, Input, InputRef, Space} from 'antd'
import 'antd/dist/antd.css'
import {ColumnsType, ColumnType} from 'antd/lib/table/interface'
import moment from 'moment'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import Highlighter from 'react-highlight-words'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import {TableComponent} from '../../../../../components'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTCardBody, QUERIES} from '../../../../../_metronic/helpers'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import {Role} from '../../../../modules/auth'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {Address} from '../../../MAP_New2/core/_models'
import {UserGroupsPagination} from '../components/pagination/UserGroupsPagination'
import {useListView} from '../core/ListViewProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {deleteMaDinhDanh} from '../core/_requests'
type Props = {}

const MaDinhDanhTable: FC<Props> = () => {
  const {auth, currentUser} = useAuth()
  const {setItemIdForUpdate, setItemIdForInfo} = useListView()
  const madinhdanh = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data: any = useMemo(() => madinhdanh, [madinhdanh])
  const {state} = useQueryRequest()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  type DataIndex = keyof DataType
  const searchInput = useRef<InputRef>(null)
  const {updateState} = useQueryRequest()
  const [FromTime, setFromTime] = useState<string | undefined>()
  const [ToTime, setToTime] = useState<string | undefined>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const VIEW_MA_DINH_DANH = currentUser?.Roles.find((item: Role) => {
    return item.Value === 64
  })

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => updateFilter(dataIndex, selectedKeys[0].toString().trim() || '')}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => updateFilter(dataIndex, selectedKeys[0].toString().trim() || '')}
            icon={<SearchOutlined />}
            size='small'
            style={{width: 90}}
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              updateFilter(dataIndex, '')
            }}
            size='small'
            style={{width: 90}}
          >
            Làm mới
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const updateFilter = (dataIndex: any, value: any) => {
    let tmp: any = {
      ...((state.filter as object) || {
        FromTime: null,
        ToTime: null,
        Name: '',
        Address: '',
        NguonID: '',
        CreateByUser: '',
      }),
    }
    tmp[dataIndex] = value
    updateState({
      filter: tmp,
      ...initialQueryState,
    })
  }

  const getColumnSearchTime = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <div className='row d-flex'>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Từ ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Từ ngày'
              name='FromTime'
              onChange={(e) => {
                if (ToTime && new Date(e.target.value) > new Date(ToTime)) setIsValid(false)
                else setIsValid(true)
                setFromTime(e.target.value)
              }}
              value={FromTime}
            />
          </div>
          <div className='col-6'>
            <label className='form-label fs-6 fw-bold'>Đến ngày:</label>
            <Input
              ref={searchInput}
              style={{marginBottom: 8, display: 'block'}}
              type='date'
              placeholder='Đến ngày'
              name='ToTime'
              onChange={(e) => {
                if (FromTime && new Date(e.target.value) < new Date(FromTime)) setIsValid(false)
                else setIsValid(true)
                setToTime(e.target.value)
              }}
              value={ToTime}
            />
          </div>
        </div>
        <div className='row'>
          {!isValid && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  Đến ngày phải lớn hơn Từ ngày
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='row d-flex justify-content-center'>
          <div className='col-4'>
            <Button
              type='primary'
              onClick={() => {
                let tmp: any = {
                  ...((state.filter as object) || {
                    FromTime: null,
                    ToTime: null,
                    Name: '',
                    Address: '',
                    NguonID: '',
                    CreateByUser: '',
                  }),
                }
                tmp['FromTime'] = FromTime ? `${FromTime}T00:00:00Z` : null
                tmp['ToTime'] = ToTime ? `${ToTime}T23:59:59Z` : null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              icon={<SearchOutlined />}
              size='small'
              className='w-100'
              disabled={!isValid}
            >
              Tìm
            </Button>
          </div>
          <div className='col-4'>
            <Button
              onClick={() => {
                setFromTime('')
                setToTime('')
                clearFilters && handleReset(clearFilters)
                let tmp: any = {
                  ...((state.filter as object) || {
                    FromTime: null,
                    ToTime: null,
                    Name: '',
                    Address: '',
                    NguonID: '',
                    CreateByUser: '',
                  }),
                }
                tmp['FromTime'] = null
                tmp['ToTime'] = null
                updateState({
                  filter: tmp,
                  ...initialQueryState,
                })
              }}
              size='small'
              className='w-100'
            >
              Làm mới
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  interface DataType {
    key: string
    Id: string
    Name: string
    NguonID: string
    UpdateTime: Date
    Info: string
    Address: Address
    CreateByUser: string
  }

  const Fdelete = (record: DataType) => {
    Swal.fire({
      title: 'Bạn chắc chắn muốn xóa?',
      text: record.Name,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Xác nhận',
      cancelButtonText: 'Hủy bỏ',
      reverseButtons: true,
      confirmButtonColor: 'red',
      width: '500',
    }).then(async function (result) {
      if (result.value) {
        await deleteItem.mutateAsync(record)
      }
    })
  }

  const deleteItem = useMutation((record: DataType) => deleteMaDinhDanh(auth?.configs, record.Id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      Swal.fire({
        icon: 'success',
        title: 'Xóa thành công',
        text: ' ',
        showConfirmButton: false,
        timer: 1500,
      })
      cancel(true)
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'Xóa thất bại',
        text: ' ',
        confirmButtonText: 'Đóng lại',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          cancel(true)
        }
      })
    },
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate('')
    setItemIdForInfo('')
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openInfoModal = (record: DataType) => {
    setItemIdForInfo(record.Id)
  }

  const openEditModal = (record: DataType) => {
    setItemIdForUpdate(record.Id)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      key: 'index',
      render: (text, record, index) => index + 1 + (state.currentPage - 1) * state.pageSize,
      align: 'center',
      width: '5%',
    },
    {
      title: 'Tên',
      dataIndex: 'Name',
      key: 'Name',
      className: 'white-space col-Info-M',
      ...getColumnSearchProps('Name'),
      render: (Name) => {
        return <span title={Name}>{Name}</span>
      },
    },
    {
      title: 'Mã',
      dataIndex: 'NguonID',
      key: 'NguonID',
      className: 'white-space col-Info-M',
      align: 'center',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'Address',
      key: 'Address',
      ...getColumnSearchProps('Address'),
      render: (_, record) => {
        if (record.Address)
          return (
            <span
              title={`${record.Info}, ${record.Address.WardName}, ${record.Address.DistrictName}, ${record.Address.CityName}`}
            >{`${record.Info}, ${record.Address.WardName}, ${record.Address.DistrictName}, ${record.Address.CityName}`}</span>
          )
      },
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'CreateByUser',
      align: 'center',
      key: 'CreateByUser',
      ...getColumnSearchProps('CreateByUser'),
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'UpdateTime',
      key: 'UpdateTime',
      align: 'center',
      ...getColumnSearchTime('UpdateTime'),
      render: (UpdateTime) => {
        return <p className='center m-0'>{moment(UpdateTime).format('DD/MM/YYYY HH:mm')}</p>
      },
    },
    {
      title: 'Hành động',
      key: 'Hành động',
      align: 'center',
      width: '12%',
      // onCell,
      render: (_, record) => (
        <Space size='middle'>
          <i
            title='Chi tiết'
            className='bi bi-info-circle fs-1'
            onClick={() => {
              openInfoModal(record)
            }}
            style={{
              cursor: 'pointer',
              padding: '5px 7px',
              color: '#1E2EC0',
              background: '#E0F2FF',
              borderRadius: '5px',
            }}
          ></i>
          {VIEW_MA_DINH_DANH && (
            <>
              <i
                className='bi bi-pencil-fill fs-1'
                onClick={() => {
                  openEditModal(record)
                }}
                style={{
                  cursor: 'pointer',
                  padding: '5px 7px',
                  color: '#1E2EC0',
                  background: '#E0F2FF',
                  borderRadius: '5px',
                }}
              ></i>
              <i
                className='bi bi-trash text-danger fs-1'
                onClick={() => Fdelete(record)}
                title='Xóa'
                style={{
                  cursor: 'pointer',
                  padding: '5px 7px',
                  background: '#FFE8EF',
                  borderRadius: '5px',
                }}
              ></i>
            </>
          )}
        </Space>
      ),
    },
  ]

  return (
    <KTCardBody className='py-4'>
      <TableComponent columns={columns} dataSource={data} />
      <UserGroupsPagination />
      {isLoading && <Loading />}
    </KTCardBody>
  )
}

export {MaDinhDanhTable}
