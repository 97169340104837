//import React, {useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {DynamicConfig} from '../../../../../configuration/config'
import {ID, QueryState, Response} from '../../../../../_metronic/helpers'
import {City, UserModel} from '../../../../modules/auth'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const USER_URL = `${API_URL}/users`

const users = '/users'
const userGroups = '/userGroups'
const deviceGroups = '/deviceGroups'
const LOCATION = '/location'

const getUsers = (configs: DynamicConfig, state: QueryState): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${users}/get-users`, {
      headers: state,
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUsersByGroupIdByFilter = (
  configs: DynamicConfig,
  state: QueryState,
  currentUser?: UserModel
): Promise<UsersQueryResponse> => {
  if (currentUser?.UserGroupIds) currentUser?.UserGroupIds.push('1')
  return axios
    .post(
      `${configs.apiUrl}${users}/get-usersByGroupIdByFilter`,
      {
        ApiFilterUser: state.filter || {
          FromTime: null,
          ToTime: null,
          FullName: '',
          Username: '',
          PhoneNumber: '',
          Email: '',
          IsActive: null,
        },
        GroupIds: currentUser?.UserGroupIds,
      },
      {
        headers: {
          currentPage: state.currentPage,
          pageSize: state.pageSize,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const ExportUser = (configs: DynamicConfig, state: QueryState): Promise<Blob> => {
  return axios
    .post(
      `${configs.apiUrl}${users}/export-user`,
      state.filter || {
        FullName: '',
        Username: '',
        PhoneNumber: '',
        Email: '',
      },
      {responseType: 'blob'}
    )
    .then((d: AxiosResponse<Blob>) => d.data)
}

const getUserById = (configs: DynamicConfig, id: string): Promise<User | undefined> => {
  return axios
    .post(`${configs.apiUrl}${users}/get-userById`, {Id: id})
    .then((d: AxiosResponse<User>) => d.data)
}

const createUser = (configs: DynamicConfig, user: User): Promise<User | undefined> => {
  return axios
    .post(`${configs.apiUrl}${users}/create-user`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (configs: DynamicConfig, user: User): Promise<User | undefined> => {
  return axios
    .put(`${configs.apiUrl}${users}/update-user`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateProfile = (configs: DynamicConfig, user: User): Promise<User | undefined> => {
  return axios
    .put(`${configs.apiUrl}${users}/update-profile`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (configs: DynamicConfig, userId: string): Promise<void> => {
  return axios
    .put(`${configs.apiUrl}${users}/delete-user`, {
      Id: userId,
    })
    .then(() => {})
}

const deleteSelectedUsers = (configs: DynamicConfig, userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${configs.apiUrl}${users}/${id}`))
  return axios.all(requests).then(() => {})
}

const getListUserGroup = (configs: DynamicConfig): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${userGroups}/userGroups/get-userGroups`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListUserGroupByGroupId = (
  configs: DynamicConfig,
  userGroupIds: string[]
): Promise<UsersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${userGroups}/get-userGroupByGroupId`,
      {
        UserGroupIds: userGroupIds,
        Filter: {
          UserGroupName: '',
          Info: '',
          CreateByUser: '',
          Username: '',
        },
      },
      {
        headers: {
          currentPage: 1,
          pageSize: 1000,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListUserGroupByUsername = (
  configs: DynamicConfig,
  username: string
): Promise<UsersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${userGroups}/get-userGroupByUser`,
      {
        Username: username,
      },
      {
        headers: {
          currentPage: 1,
          pageSize: 1000,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListDeviceGroups = (configs: DynamicConfig): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${deviceGroups}/get-deviceGroups`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListDeviceGroupByUsername = (
  configs: DynamicConfig,
  username: string
): Promise<UsersQueryResponse> => {
  return axios
    .post(
      `${configs.apiUrl}${deviceGroups}/get-deviceGroupByUser`,
      {Username: username},
      {
        headers: {
          currentPage: 1,
          pageSize: 1000,
        },
      }
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getListDeviceGroup = (configs: DynamicConfig): Promise<UsersQueryResponse> => {
  return axios
    .get(`${configs.apiUrl}${deviceGroups}/get-deviceGroups`, {
      headers: {
        currentPage: 1,
        pageSize: 1000,
      },
    })
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const changePassword = (
  configs: DynamicConfig,
  id: string,
  password: string,
  passwordNew: string
): Promise<User | undefined> => {
  return axios
    .put(`${configs.apiUrl}${users}/change-password`, {
      Id: id,
      Password: password,
      PasswordNew: passwordNew,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const changePasswordAdmin = (
  configs: DynamicConfig,
  Username: string,
  password: string
): Promise<User | undefined> => {
  return axios
    .put(`${configs.apiUrl}${users}/change-password-admin`, {
      Username: Username,
      Password: password,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const changePasswordFirst = (
  configs: any,
  Username: string,
  password: string,
  PasswordNew: string
): Promise<User | undefined> => {
  return axios
    .put(`${configs.apiUrl}${users}/change-password`, {
      Username: Username,
      Password: password,
      PasswordNew: PasswordNew,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const changeSecurityQuestions = (
  configs: DynamicConfig,
  questions1: string,
  answer1: string,
  questions2: string,
  answer2: string,
  Password: string
): Promise<User | undefined> => {
  return axios
    .put(`${configs.apiUrl}${users}/change-securityQuestions`, {
      Questions1: questions1,
      Answer1: answer1,
      Questions2: questions2,
      Answer2: answer2,
      Password: Password,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const ForgotPassworded = (
  configs: DynamicConfig,
  questions1: string,
  answer1: string,
  questions2: string,
  answer2: string,
  email: string
): Promise<User | undefined> => {
  return axios
    .post(`${configs.apiUrl}${users}/check-forgotPassword`, {
      Questions1: questions1,
      Answer1: answer1,
      Questions2: questions2,
      Answer2: answer2,
      Email: email,
    })
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getListCityByUserId = (configs: DynamicConfig, userId: string): Promise<Array<City>> => {
  return axios
    .post(`${configs.apiUrl}${LOCATION}/get-listCityByUserId`, {
      UserId: userId,
    })
    .then((response: AxiosResponse<Array<City>>) => response.data)
}

export {
  getUsers,
  getUsersByGroupIdByFilter,
  getUserById,
  deleteUser,
  deleteSelectedUsers,
  createUser,
  updateUser,
  changePassword,
  getListCityByUserId,
  getListUserGroup,
  getListUserGroupByGroupId,
  getListDeviceGroups,
  getListDeviceGroupByUsername,
  getListDeviceGroup,
  updateProfile,
  ExportUser,
  changeSecurityQuestions,
  ForgotPassworded,
  getListUserGroupByUsername,
  changePasswordAdmin,
  changePasswordFirst
}
