import {Select} from 'antd'
import clsx from 'clsx'
import {useFormik} from 'formik'
import MultiSelect from 'multiselect-react-dropdown'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {Loading} from '../../../../../_metronic/layout/components/loading/Loading'
import compareLabelMatch from '../../../../../untils/compare-label-match'
import {City} from '../../../../modules/auth'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {District, Ward} from '../../../MAP_New2/core/_models'
import {getProvinces} from '../../../MAP_New2/core/_requests'
import {getDistrictByCityId, getWardByDistrictId} from '../../../diachi/diachi-list/core/_requests'
import {DongBo} from '../../../dongbo/dongbo-list/core/_models'
import {getCauHinhDongBos} from '../../../dongbo/dongbo-list/core/_requests'
import {cheDoPhat, maDinhDanh} from '../../../madinhdanh/madinhdanh/core/_models'
import {getAllMaDinhDanh} from '../../../madinhdanh/madinhdanh/core/_requests'
import {useListView} from '../core/ListViewProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {DeviceGroup, initialDeviceGroup} from '../core/_models'
import {createDeviceGroup, updateDeviceGroup} from '../core/_requests'

type Props = {
  isUserLoading: boolean
  deviceGroup: DeviceGroup
}

const editUserSchema = Yup.object().shape({
  DeviceGroupName: Yup.string()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .trim()
    .required('Tên nhóm không được để trống'),
  ImeiMaster: Yup.string()
    .trim()
    .min(15, 'Mã IMEI không đủ 15 ký tự')
    .max(15, 'Mã IMEI quá 15 ký tự')
    .required('Mã IMEI không được để trống'),
  // .length(15, 'Mã IMEI phải là 15 ký tự'),
  // .matches(/^\d{15}$/, 'Mã IMEI phải là dãy 15 số'),
  Info: Yup.string()
    .trim()
    .min(3, 'Tối thiểu 3 ký tự')
    .max(250, 'Tối đa 250 ký tự')
    .required('Thông tin nhóm không được để trống'),
})

function generateRandom16DigitNumber() {
  let result = ''
  for (let i = 0; i < 15; i++) {
    const randomDigit = Math.floor(Math.random() * 10)
    result += randomDigit
  }
  return result
}

const DaiTruyenThanhEditModalForm: FC<Props> = ({deviceGroup, isUserLoading}) => {
  const {auth} = useAuth()

  const {setItemIdForUpdate} = useListView()
  const {state} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const [listMaDinhDanh, setListMaDinhDanh] = useState<Array<maDinhDanh>>([])
  const [listCheDoPhat, setListCheDoPhat] = useState<Array<cheDoPhat>>([])
  const [listCauHinhDongBo, setListCauHinhDongBo] = useState<Array<DongBo>>([])
  const [listCauHinhDongBoCurrent, setListCauHinhDongBoCurrent] = useState<Array<DongBo>>([])
  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [lstWard, setLstWard] = useState<Array<Ward>>([])
  const [city, setCity] = useState<string>(deviceGroup.Address?.CityName || '')
  const [district, setDistrict] = useState<string>(deviceGroup.Address?.DistrictName || '')
  const [ward, setWard] = useState(deviceGroup.Address?.WardName || '')

  const [CityId, setCityId] = useState<string>('')
  const [DistrictId, setDistrictId] = useState<string>('')
  const [WardId, setWardId] = useState<string>('')

  const [deviceGroupForEdit] = useState<DeviceGroup>({
    ...deviceGroup,
    DeviceGroupName: deviceGroup.DeviceGroupName || '',
    ImeiMaster: deviceGroup.ImeiMaster || generateRandom16DigitNumber(),
    Info: deviceGroup.Info || '',
    IsActive: deviceGroup.IsActive || initialDeviceGroup.IsActive,
    NguonID: deviceGroup.NguonID,
    StreamType: deviceGroup.StreamType,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate('')
  }

  useEffect(() => {
    getCauHinhDongBos(auth?.configs).then((data) => {
      setListCauHinhDongBo(data.Data || [])
      if (data.Data && deviceGroup.CauHinhDongBos) {
        let lst: Array<DongBo> = []
        for (let i = 0; i < data.Data.length; i++) {
          for (let j = 0; j < deviceGroup.CauHinhDongBos.length; j++) {
            if (data.Data[i].Id === deviceGroup.CauHinhDongBos[j]) {
              lst.push(data.Data[i])
              break
            }
          }
        }
        setListCauHinhDongBoCurrent(lst)
      }
    })

    setListCheDoPhat([
      {
        Id: 0,
        Name: 'Tiêu chuẩn',
      },
      {
        Id: 1,
        Name: 'Nâng cao',
      },
    ])

    getProvinces(auth?.configs).then((data) => {
      if (data.length > 0) {
        setLstCity(data)
        let cId = data[0].CityId
        setCityId(cId ?? '')
        if (deviceGroup.Address?.CityName) {
          let arr = data.filter((c: City) => {
            return c.CityName === city
          })
          if (arr.length > 0) cId = arr[0].CityId
        } else setCity(data[0].CityName || '')

        getDistrictByCityId(auth?.configs, cId || '').then((dataD) => {
          setLstDistrict(dataD)
          let dId = dataD[0].DistrictId
          if (deviceGroup.Address?.WardName) {
            let arr = dataD.filter(function (g: District) {
              return g.DistrictName === district
            })
            if (arr.length > 0) dId = arr[0].DistrictId
          } else setDistrict(dataD[0].DistrictName || '')
          getWardByDistrictId(auth?.configs, cId || '', dId || '').then((dataW) => {
            setLstWard(dataW)
            if (!deviceGroup.Address?.WardName) setWard(dataW[0].WardName || '')
          })
        })
      }
    })
  }, [])

  useEffect(() => {
    if (CityId) {
      const customState: any = state
      customState.pageSize = 999
      customState.filter.Location = {
        CityId: CityId,
        DistrictId: DistrictId,
        WardId: WardId,
      }
      getAllMaDinhDanh(auth?.configs, customState).then((data) => {
        if (data.Data) setListMaDinhDanh(data.Data)
      })
    }
  }, [CityId, DistrictId, WardId])

  //const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: deviceGroupForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      values.CauHinhDongBos = []
      listCauHinhDongBoCurrent.map((c: DongBo) => {
        if (c.Id) values.CauHinhDongBos?.push(c.Id)
      })
      values.Address = {
        CityName: city,
        DistrictName: district,
        WardName: ward,
      }
      try {
        if (values.Id !== '0') {
          await updateDeviceGroup(auth?.configs, values)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Cập nhật thông tin thành công',
                text: ' ',
                showConfirmButton: false,
                timer: 1500,
              })
              setSubmitting(true)
              cancel(true)
            })
            .catch((error) => {
              Swal.fire({
                title: error.response.data || 'Cập nhật thất bại',
                icon: 'error',
              })
            })
        } else {
          await createDeviceGroup(auth?.configs, values)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Thêm nhóm thành công',
                text: ' ',
                showConfirmButton: false,
                timer: 1500,
              })
              setSubmitting(true)
              cancel(true)
            })
            .catch((error) => {
              Swal.fire({
                title: error.response.data || 'Thêm mới thất bại',
                icon: 'error',
              })
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
      }
    },
  })

  const onChangeDistrict = (districtId: string) => {
    setDistrict(lstDistrict.find((item) => item.DistrictId === districtId)?.DistrictName || '')
    let cId
    let arr = lstCity.filter((c: City) => {
      return c.CityName === city
    })
    if (arr.length > 0) cId = arr[0].CityId
    getWardByDistrictId(
      auth?.configs,
      cId || '',
      lstDistrict.find((item) => item.DistrictId === districtId)?.DistrictId || ''
    ).then((data) => {
      setLstWard(data)
      setWard(data[0].WardName || '')
    })
  }

  const onChangeCity = (value: string) => {
    setCity(lstCity.find((item) => item.CityId === value)?.CityName || '')
    getDistrictByCityId(
      auth?.configs,
      lstCity.find((item) => item.CityId === value)?.CityId || ''
    ).then((data) => {
      setLstDistrict(data)
      setDistrict(data[0].DistrictName || '')
      getWardByDistrictId(
        auth?.configs,
        lstCity.find((item) => item.CityId === value)?.CityId || '',
        data[0].DistrictId || ''
      ).then((dataW) => {
        setLstWard(dataW)
        setWard(dataW[0].WardName || '')
      })
    })
  }

  return (
    <div className='nguoi-dung-body'>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='row' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-4 mb-2'>Tên nhóm</label>
              <input
                placeholder='Tên nhóm'
                {...formik.getFieldProps('DeviceGroupName')}
                type='text'
                name='DeviceGroupName'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.DeviceGroupName && formik.errors.DeviceGroupName},
                  {
                    'is-valid': formik.touched.DeviceGroupName && !formik.errors.DeviceGroupName,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.DeviceGroupName && formik.errors.DeviceGroupName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.DeviceGroupName}
                    </span>
                  </div>
                </div>
              )}
              {/* end::Label */}
            </div>
          </div>
          {/* end::Input group */}

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='required fw-bold fs-4 mb-2'>Thông tin nhóm</label>
              <input
                placeholder='Thông tin nhóm'
                {...formik.getFieldProps('Info')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Info && formik.errors.Info},
                  {
                    'is-valid': formik.touched.Info && !formik.errors.Info,
                  }
                )}
                type='text'
                name='Info'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.Info && formik.errors.Info && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.Info}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-5 mt-5'>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Tỉnh</label>
              <Select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                value={city}
                onChange={(value) => {
                  setDistrictId('')
                  setCityId(value)
                  onChangeCity(value)
                }}
                options={lstCity.map((item) => ({label: item.CityName, value: item.CityId}))}
                showSearch
                filterOption={compareLabelMatch}
              />

              {/* end::Label */}
            </div>
            <div className='col-4'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Huyện</label>
              <Select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('DistrictName')}
                value={district}
                allowClear
                onChange={(value) => {
                  if (value) {
                    setDistrictId(value)
                    setWardId('')
                    onChangeDistrict(value)
                  } else {
                    setWard('')
                    setDistrict('')
                  }
                }}
                options={lstDistrict?.map((item) => ({
                  label: item.DistrictName,
                  value: item.DistrictId,
                }))}
                showSearch
                filterOption={compareLabelMatch}
              />
            </div>
            <div className='col-4'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Xã</label>
              <Select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                {...formik.getFieldProps('WardName')}
                allowClear
                value={ward}
                onChange={(value) => {
                  if (value) {
                    const wardName = lstWard.find((item) => item.WardId === value)?.WardName || ''
                    setWard(wardName)
                    setWardId(value)
                  } else {
                    setWard('')
                  }
                }}
                showSearch
                options={lstWard.map((item) => ({
                  label: item.WardName,
                  value: item.WardId,
                }))}
                filterOption={compareLabelMatch}
              />

              {/* end::Label */}
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-6'>
              <label className='fw-bold fs-4 mb-2'>IMEI bộ phát</label>
              <input
                placeholder='IMEI bộ phát'
                {...formik.getFieldProps('ImeiMaster')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.ImeiMaster && formik.errors.ImeiMaster},
                  {
                    'is-valid': formik.touched.ImeiMaster && !formik.errors.ImeiMaster,
                  }
                )}
                type='string'
                name='ImeiMaster'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.ImeiMaster && formik.errors.ImeiMaster && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.ImeiMaster}
                  </span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-4 mb-2'>Mã định danh</label>
              <Select
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                showSearch={true}
                filterOption={compareLabelMatch}
                onChange={(value) => formik.setFieldValue('NguonID', value)}
                defaultValue={deviceGroup.NguonID ? deviceGroup.NguonID : ''}
                options={[
                  {value: '', label: 'Chọn mã định danh'},
                  ...listMaDinhDanh.map((item) => ({
                    value: item.NguonID,
                    label: `${item.NguonID} - ${item.Name}`,
                  })),
                ]}
                placeholder='Chọn kênh FM'
                className={clsx(
                  'form-select form-select-solid fw-bolder',
                  {'is-invalid': formik.touched.NguonID && formik.errors.NguonID},
                  {
                    'is-valid': formik.touched.NguonID && !formik.errors.NguonID,
                  }
                )}
                size='large'
              />

              {/* <input
                placeholder='Mã định danh'
                {...formik.getFieldProps('NguonID')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.NguonID && formik.errors.NguonID },
                  {
                    'is-valid': formik.touched.NguonID && !formik.errors.NguonID,
                  }
                )}
                type='string'
                name='NguonID'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              /> */}
              {formik.touched.NguonID && formik.errors.NguonID && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {formik.errors.NguonID}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='fw-bold fs-4 mb-2'>Chế độ phát thanh</label>
              <Select
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                showSearch={true}
                filterOption={compareLabelMatch}
                onChange={(value) => formik.setFieldValue('StreamType', value)}
                defaultValue={deviceGroup.StreamType ? deviceGroup.StreamType : ''}
                options={[
                  {value: '', label: 'Chọn chế độ phát'},
                  ...listCheDoPhat.map((item) => ({
                    value: item.Id,
                    label: item.Name,
                  })),
                ]}
                placeholder='Chọn kênh FM'
                className={clsx(
                  'form-select form-select-solid fw-bolder',
                  {'is-invalid': formik.touched.StreamType && formik.errors.StreamType},
                  {
                    'is-valid': formik.touched.StreamType && !formik.errors.StreamType,
                  }
                )}
                size='large'
              />
            </div>
          </div>

          <div className='row mt-5' style={{alignItems: 'center'}}>
            <div className='col-12'>
              {/* begin::Label */}
              <label className='fw-bold fs-4 mb-2'>Đồng bộ dữ liệu</label>
              <MultiSelect
                className='form-control form-control-solid mb-3 mb-lg-0'
                displayValue='Name'
                onKeyPressFn={function noRefCheck() {}}
                onRemove={(event) => {
                  setListCauHinhDongBoCurrent(event)
                }}
                onSearch={function noRefCheck() {}}
                onSelect={(event) => {
                  setListCauHinhDongBoCurrent(event)
                }}
                placeholder={''}
                id='Id'
                options={listCauHinhDongBo}
                selectedValues={listCauHinhDongBoCurrent}
                showCheckbox
                emptyRecordMsg={'Không có giá trị'}
              />
              {formik.touched.CauHinhDongBos && formik.errors.CauHinhDongBos && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='text-danger' role='alert'>
                      {formik.errors.CauHinhDongBos}
                    </span>
                  </div>
                </div>
              )}
              {/* /* end::Input */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center mt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-outline-primary me-3 cancel'
            data-kt-users-modal-action='cancel'
          >
            Hủy bỏ
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            {deviceGroup.Id === '0' && <span className='indicator-label'>Thêm mới</span>}
            {deviceGroup.Id && deviceGroup.Id?.length > 1 && (
              <span className='indicator-label'>Cập nhật</span>
            )}

            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Vui lòng đợi...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loading />}
    </div>
  )
}

export {DaiTruyenThanhEditModalForm}
